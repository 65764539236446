<form novalidate="" [formGroup]="form" (submit)="submit()" class="new-idea-dialog">
  <div class="dialog-title">
    <div class="left">
      <h1>{{ 'common.text3' | craftTerm }}</h1>
      <mat-error *ngIf="isSubmitted && form.invalid" class="general-validation">
        <craft-icon class="svg-icon" name="general--error"></craft-icon> Please fill {{ formErrors }} required fields
      </mat-error>
    </div>
    <div class="right">
      <craft-attach-select-button class="attach-btn" (fileSelect)="attachFiles($event)" matTooltip="Attach file" matTooltipPosition="above">
        <span class="text-size-14">Attach</span>
        <craft-icon class="svg-icon text-size-11 ml-4" name="formatbar--attach-sm"></craft-icon>
      </craft-attach-select-button>
      <button type="button" (click)="close()" class="btn btn-close text-size-15">
        <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
      </button>
    </div>
  </div>

  <div mat-dialog-content="" #scrollConteiner class="dialog-content">
    <div class="mt-20">
      <mat-error *ngIf="isSubmitted && form.controls['categoryId'].invalid">
        {{ 'common.text15' | craftTerm }}
      </mat-error>
      <craft-idea-category-dropdown formControlName="categoryId" (enterPressed)="nameInput.focus()" (selectNew)="selectNewCategory($event)">
      </craft-idea-category-dropdown>
    </div>

    <div class="mt-6">
      <mat-error *ngIf="isSubmitted && form.controls['name'].invalid">
        {{ 'common.text5' | craftTerm }}
      </mat-error>

      <textarea
        class="textarea-input"
        [attr.placeholder]="'common.text6' | craftTerm"
        formControlName="name"
        maxlength="255"
        craftAutoresize=""
        [disableLineBreak]="true"
        #nameInput
      ></textarea>
    </div>

    <craft-text-editor class="i-desc" formControlName="description" [placeholder]="'common.text51' | craftTerm"> </craft-text-editor>

    <craft-idea-custom-controls [isFormSubmitted]="isSubmitted" [formArray]="form.get('custom')"></craft-idea-custom-controls>

    <craft-idea-attachments-progress formControlName="attaches"></craft-idea-attachments-progress>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex">
    <button class="btn dialog-btn shrink-0" type="button" (click)="close()" mat-button="">Cancel</button>
    <button class="btn btn-blue dialog-btn shrink-0" type="submit" [disabled]="isSending || isAnyUploading" mat-button="">Save</button>
  </div>
</form>
