<ng-container *ngFor="let control of controls">
  <ng-container *ngTemplateOutlet="cutomControl; context: { control: control, errorMessage: getErrorMessage(control) }"></ng-container>
</ng-container>

<ng-template #cutomControl let-control="control" let-errorMessage="errorMessage">
  <craft-validation-message [isShown]="errorMessage">
    {{ errorMessage }}
  </craft-validation-message>
  <craft-idea-custom-control [field]="fieldsMap.get(control.value.id)" [formControl]="control" class="control"></craft-idea-custom-control>
</ng-template>
