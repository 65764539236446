import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBCraftForm } from 'src/app/core/models/forms.models';
import {
  FORM_ADD_SUCCESS,
  FORM_DEL_SUCCESS,
  FORM_GET_LIST_SUCCESS,
  FORM_GET_ONE_SUCCESS,
  FORM_SAVE,
  FORM_SAVE_SUCCESS,
  FORM_SELECT,
  FormsAction,
} from 'src/app/core/store/actions/forms';

export type FormsState = {
  readonly savingId?: string;
  readonly loadingId?: string;
  readonly selectedId?: string;
  readonly ids: readonly string[];
  readonly forms: StringTMap<DBCraftForm>;
};

export const initialState: FormsState = {
  savingId: void 0,
  loadingId: void 0,
  selectedId: void 0,
  ids: [],
  forms: {},
};

export function reducer(state = initialState, action: FormsAction): FormsState {
  switch (action.type) {
    case FORM_SELECT: {
      const id = action.payload;
      return {
        ...state,
        selectedId: id,
      };
    }

    case FORM_GET_LIST_SUCCESS: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case FORM_GET_ONE_SUCCESS: {
      const form = action.payload;
      return {
        ...state,
        forms: {
          ...state.forms,
          [form.id]: {
            ...form,
          },
        },
      };
    }

    // save
    case FORM_SAVE: {
      const form = action.payload;
      return {
        ...state,
        savingId: form.id,
        forms: {
          ...state.forms,
          [form.id]: {
            ...form,
          },
        },
      };
    }
    case FORM_SAVE_SUCCESS: {
      return {
        ...state,
        savingId: void 0,
      };
    }

    // add
    case FORM_ADD_SUCCESS: {
      const form = action.payload;
      return {
        ...state,
        ids: [...state.ids, form.id],
        selectedId: state.selectedId || form.id,
        forms: {
          ...state.forms,
          [form.id]: form,
        },
      };
    }

    // delete
    case FORM_DEL_SUCCESS: {
      const formId = action.payload;
      delete state.forms[formId];

      return {
        ...state,
        savingId: formId,
        selectedId: state.selectedId === formId ? void 0 : state.selectedId,
        ids: state.ids.filter((id) => id !== formId),
        forms: {
          ...state.forms,
        },
      };
    }

    default:
      return state;
  }
}

const getForms = (state: FormsState) => state.forms;
const getFormsIdsList = (state: FormsState) => state.ids;
const getFormsSavingId = (state: FormsState) => state.savingId;
const getFormsLoadingId = (state: FormsState) => state.loadingId;
const getFormsSelectedId = (state: FormsState) => state.selectedId;

export const getFormsStateSelector = createFeatureSelector<FormsState>('formsState');
export const getFormsIdsSelector = createSelector(getFormsStateSelector, getFormsIdsList);
export const getFormsSavingIdSelector = createSelector(getFormsStateSelector, getFormsSavingId);
export const getFormsLoadingIdSelector = createSelector(getFormsStateSelector, getFormsLoadingId);
export const getFormsSelector = createSelector(getFormsStateSelector, getForms);
export const getSelectedFormIdSelector = createSelector(getFormsStateSelector, getFormsSelectedId);
export const getSelectedFormSelector = createSelector(getFormsStateSelector, getSelectedFormIdSelector, (state, id) =>
  id !== void 0 ? state.forms[id] : void 0,
);
export const getFormsListSelector = createSelector(getFormsStateSelector, getFormsIdsSelector, (state, ids) => ids.map((id) => state.forms[id]));
