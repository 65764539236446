import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomField, CustomFieldOption } from 'src/app/core/models/custom-fields.models';
import { IdeaCustomFieldValue, IdeaCustomValueType } from 'src/app/core/models/idea.models';

@Component({
  selector: 'craft-idea-custom-control',
  templateUrl: './idea-custom-control.component.html',
  styleUrls: ['./idea-custom-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IdeaCustomControlComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IdeaCustomControlComponent implements ControlValueAccessor, OnChanges {
  @Input() public field: CustomField | undefined;

  public isRequired = false;
  public disabled = false;
  public icfv: IdeaCustomFieldValue | undefined;
  public checked = new Set<string>();

  private onTouched = () => {};
  private onChange = (_: IdeaCustomFieldValue) => {};

  constructor(private readonly ref: ChangeDetectorRef) {}

  public get singleValue(): string {
    return Array.isArray(this.icfv?.value) ? this.icfv!.value[0] : '';
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.field) {
      this.isRequired = Array.isArray(this.field?.validation) //
        ? this.field!.validation.some((v) => v.type === 'required')
        : false;
    }
  }

  public writeValue(obj: IdeaCustomFieldValue): void {
    this.icfv = obj;

    if (Array.isArray(this.icfv?.value)) {
      this.checked = new Set(this.icfv.value);
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  public identifyOption(index: number, item?: CustomFieldOption): string | number {
    return item?.guid || index;
  }

  public updateSingleValue(insideValue: IdeaCustomValueType) {
    if (!this.icfv) return;

    this.icfv = { ...this.icfv, value: insideValue };

    this.onTouched();
    this.onChange(this.icfv);
    this.ref.markForCheck();
  }

  public updateMultiValue(guid: string, checked?: boolean) {
    if (!this.icfv) return;

    if (!Array.isArray(this.icfv.value)) {
      this.checked = new Set([guid]);
    } else if (checked) {
      this.checked = new Set([...this.icfv.value, guid]);
    } else {
      this.checked = new Set(this.icfv.value.filter((v) => v !== guid));
    }

    const value = Array.from(this.checked.values());
    this.icfv = { ...this.icfv, value: value.length > 0 ? value : null };

    this.onTouched();
    this.onChange(this.icfv);
    this.ref.markForCheck();
  }
}
