<mat-form-field class="craft-tags-field">
  <mat-chip-grid #chipList [disabled]="disabled">
    <mat-chip *ngFor="let tag of tags" (removed)="remove(tag)">
      <craft-icon matChipRemove class="svg-icon text-size-9" name="general--close-sm"></craft-icon>
      <span [title]="tag" class="ellipsis">{{ tag }}</span>
    </mat-chip>
    <input
      #tagInput=""
      class="tag-input"
      maxlength="255"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="false"
      (matChipInputTokenEnd)="add($event, tagInput)"
      (keyup)="inputChanged$.next(tagInput.value)"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayName" (optionSelected)="selectAutocompleteTag($event, tagInput)">
    <mat-option *ngFor="let tag of autocompleteTags$ | async | slice: 0 : 20; trackBy: identifyTags" [value]="tag" [title]="tag.title">
      {{ tag.title }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
