import { AbstractControl, ValidatorFn } from '@angular/forms';
import { StringAnyMap, StronglyKeyedMap } from 'src/app/core/models/common.models';
import { CustomField, CustomFieldValidation, CustomValidationType, CUSTOM_FIELDS_VALIDATIONS } from 'src/app/core/models/custom-fields.models';
import { IdeaCustomFieldValue } from 'src/app/core/models/idea.models';

type ValidationFn = (value: any, v: CustomFieldValidation) => CustomFieldValidation | undefined;

export const getCustomFieldValidator = (field: CustomField): ValidatorFn => {
  return (control: AbstractControl): StringAnyMap | null => {
    if (!field.validation) return null;

    const result = field.validation.find((v) => test(control.value, v) !== void 0);
    if (!result) return null;

    return {
      customFieldValidation: {
        ...result,
        value: control.value,
        message: CUSTOM_FIELDS_VALIDATIONS[result.type].message,
      },
    };
  };
};

const test = (val: IdeaCustomFieldValue, v: CustomFieldValidation) => {
  const fn = VALIDATIONS[v.type];
  return typeof fn === 'function' ? fn(val, v) : () => {};
};

const isRequired = (val: IdeaCustomFieldValue, v: CustomFieldValidation) => {
  return !val || !val.value ? v : void 0;
};

const isUrl = (val: IdeaCustomFieldValue, v: CustomFieldValidation) => {
  if (!val) return v;
  if (!val.value || typeof val.value !== 'string') return;

  const urlReg = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
  if (urlReg.test(val.value)) return v;

  return;
};

const VALIDATIONS: StronglyKeyedMap<CustomValidationType, ValidationFn> = {
  required: isRequired,
  url: isUrl,
};
