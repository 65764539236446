import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import {
  CustomField,
  CustomFieldOption,
  CustomFieldValidation,
  CustomValidationType,
  CUSTOM_FIELDS_VALIDATIONS,
} from 'src/app/core/models/custom-fields.models';
import { IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { customFieldOptionValidator } from 'src/app/shared/validators/custom-field-option.validator';
import { getCustomFieldValidator } from 'src/app/shared/validators/custom-field.validator';
import { getMaxlengthValidator } from 'src/app/shared/validators/maxlength.validator';
import { getNumberValidator } from 'src/app/shared/validators/number.validator';

@Injectable({ providedIn: 'root' })
export class CustomFieldsFormsService {
  public getUpdatedValidations(type: CustomValidationType, isEnabled: boolean, validations?: readonly CustomFieldValidation[]): CustomFieldValidation[] {
    let old = Array.isArray(validations) ? validations.filter((v) => v.type !== type) : [];
    if (isEnabled) {
      return [...old, { ...CUSTOM_FIELDS_VALIDATIONS[type] }];
    } else {
      return old;
    }
  }

  public createFormArray(fields: readonly CustomField[], values: readonly Readonly<IdeaCustomFieldValue>[] = []): UntypedFormArray {
    const valuesMap = values.reduce((map, v) => {
      map.set(v.id, v);
      return map;
    }, new Map<string, Readonly<IdeaCustomFieldValue>>());

    const controls = fields.map((field) => {
      const value = valuesMap.get(field.id) || { id: field.id, guid: field.guid, value: null };
      return new UntypedFormControl(value, [
        getCustomFieldValidator(field), //
        getNumberValidator(field),
        getMaxlengthValidator(field, 'Answer cannot be longer than @count characters'),
      ]);
    });

    return new UntypedFormArray(controls);
  }

  public createOptionsFormArray(options: readonly CustomFieldOption[]): UntypedFormArray {
    const controls = options.map((opt) => this.createOptionFormControl(opt));
    return new UntypedFormArray(controls);
  }

  public createOptionFormControl(option: CustomFieldOption): AbstractControl {
    return new UntypedFormControl(option, customFieldOptionValidator);
  }
}
