import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { CraftForm, DBCraftForm, FormsListResponse } from 'src/app/core/models/forms.models';
import { FormsBackendService } from 'src/app/core/services/backend/forms-backend.service';
import {
  FormsReqAddAction,
  FormsReqAddErrorAction,
  FormsReqAddSuccessAction,
  FormsReqDelAction,
  FormsReqDelErrorAction,
  FormsReqDelSuccessAction,
  FormsReqListAction,
  FormsReqListErrorAction,
  FormsReqListSuccessAction,
  FormsReqOneAction,
  FormsReqOneErrorAction,
  FormsReqOneSuccessAction,
  FormsReqSaveAction,
  FormsReqSaveErrorAction,
  FormsReqSaveSuccessAction,
  FormsSelectAction,
} from 'src/app/core/store/actions/forms';
import {
  getFormsIdsSelector,
  getFormsListSelector,
  getFormsSavingIdSelector,
  getFormsSelector,
  getSelectedFormSelector,
} from 'src/app/core/store/reducers/forms';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class FormsStoreService {
  public readonly map$ = this.store$.pipe(select(getFormsSelector));
  public readonly ids$ = this.store$.pipe(select(getFormsIdsSelector));
  public readonly list$ = this.store$.pipe(select(getFormsListSelector));
  public readonly selected$ = this.store$.pipe(select(getSelectedFormSelector));
  public readonly savingId$ = this.store$.pipe(select(getFormsSavingIdSelector));

  constructor(
    private store$: Store<CoreState>, //
    private fbs: FormsBackendService,
  ) {}

  public selectById(id: string | undefined): void {
    this.store$.dispatch(new FormsSelectAction(id));
  }

  public loadList(): Observable<FormsListResponse> {
    this.store$.dispatch(new FormsReqListAction());

    return this.fbs.getList().pipe(
      map((res) => {
        this.store$.dispatch(new FormsReqListSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FormsReqListErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public loadOne(id: string): Observable<DBCraftForm> {
    this.store$.dispatch(new FormsReqOneAction(id));

    return this.fbs.getDetails(id).pipe(
      map((form) => {
        this.store$.dispatch(new FormsReqOneSuccessAction(form));
        return form;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FormsReqOneErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public add(form: CraftForm): Observable<DBCraftForm> {
    this.store$.dispatch(new FormsReqAddAction(form));

    return this.fbs.add(form).pipe(
      map((res) => {
        this.store$.dispatch(new FormsReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FormsReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public update(form: DBCraftForm): Observable<DBCraftForm> {
    this.store$.dispatch(new FormsReqSaveAction(form));

    return this.fbs.update(form).pipe(
      map((res) => {
        this.store$.dispatch(new FormsReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FormsReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public save(form: CraftForm): Observable<DBCraftForm> {
    return form.id ? this.update(form as DBCraftForm) : this.add(form);
  }

  public delete(formId: string): Observable<void> {
    this.store$.dispatch(new FormsReqDelAction(formId));

    return this.fbs.delete(formId).pipe(
      map(() => {
        this.store$.dispatch(new FormsReqDelSuccessAction(formId));
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new FormsReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
