import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';

@Component({
  selector: 'craft-idea-custom-controls',
  templateUrl: './idea-custom-controls.component.html',
  styleUrls: ['./idea-custom-controls.component.scss'],
})
export class IdeaCustomControlsComponent implements OnInit, OnDestroy {
  @Input() public formArray: AbstractControl | null;
  @Input() public isFormSubmitted = false;
  @Input() public fieldsMap: Map<string, CustomField>;

  private destroy$ = new Subject<void>();

  constructor(private readonly customFieldsSrv: CustomFieldsStoreService) {}

  public get fa() {
    return this.formArray as UntypedFormArray;
  }

  public get controls() {
    return this.fa.controls as UntypedFormControl[];
  }

  public ngOnInit() {
    if (!this.fieldsMap) {
      this.customFieldsSrv.map$.pipe(takeUntil(this.destroy$)).subscribe((map) => {
        this.fieldsMap = map;
      });
    }
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getErrorMessage(control: UntypedFormControl): string | undefined {
    if (this.isFormSubmitted && control.errors?.customFieldValidation) {
      return control.errors.customFieldValidation.message;
    } else if (control.touched) {
      return control.errors?.number?.message || control.errors?.maxLength?.message;
    }

    return;
  }
}
