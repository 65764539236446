import { Component, ElementRef, Inject, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Observable, Subject, combineLatest, forkJoin, of, throwError } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { CUSTOM_FIELDS_VALIDATIONS, CustomField } from 'src/app/core/models/custom-fields.models';
import { Idea, IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { AttachEvent, AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { RouterExtService } from 'src/app/core/services/router-ext.service';
import { UserPermissionsService } from 'src/app/core/services/user-permissions.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';
import { FormsStoreService } from 'src/app/core/store/services/forms-store.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { ImportancesStoreService } from 'src/app/core/store/services/importances-store.service';
import { getFormValidationErrors } from 'src/app/core/utils/form-utils';
import { CustomFieldsFormsService } from 'src/app/shared/services/custom-fields-forms.service';

export type CreateIdeaDialogInput = {
  categoryId?: string;
  importanceId?: string;
};

@Component({
  templateUrl: './idea-create.component.html',
  styleUrls: ['./idea-create.component.scss'],
})
export class IdeaCreateDialogComponent implements OnInit, OnDestroy {
  @ViewChild('scrollConteiner') public scrollConteinerRef: ElementRef;

  public form: UntypedFormGroup;
  public isSending = false;
  public isSubmitted = false;
  public newIdea: Readonly<Idea>;
  public selectedCategoryId?: Readonly<Category>;
  public newCategory?: Readonly<Category>;
  public customFieldsMap: Map<string, CustomField> = new Map();

  private destroy$ = new Subject<void>();

  constructor(
    public ups: UserPermissionsService,

    private router: Router,
    private attachService: AttachService,
    private entityFactory: EntityFactory,
    private ideasStore: IdeasStoreService,
    private routerExService: RouterExtService,
    private categoriesStore: CategoriesStoreService,
    private customFieldsStore: CustomFieldsStoreService,
    private customFieldsForms: CustomFieldsFormsService,
    private importancesStore: ImportancesStoreService,
    private formsStore: FormsStoreService,
    private dialogRef: MatDialogRef<IdeaCreateDialogComponent, void>,
    @Inject(MAT_DIALOG_DATA) @Optional() public data?: CreateIdeaDialogInput,
  ) {}

  public get isAnyUploading() {
    return this.attachService.uploadingSize > 0;
  }

  public get formErrors(): number {
    if (!this.isSubmitted) return 0;
    if (this.form.valid) return 0;

    return getFormValidationErrors(this.form).length;
  }

  public ngOnInit() {
    this.buildNewIdea()
      .pipe(
        switchMap((idea) =>
          this.buildForm(idea).pipe(
            map((form) => ({ idea, form })), //
          ),
        ),
        takeUntil(this.destroy$),
      )
      .subscribe(({ idea, form }) => {
        this.selectedCategoryId = form.controls.categoryId.value;
        this.form = form;
        this.newIdea = idea;
      });

    this.attachService.attach$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(this.onAttachAdded);
    this.subscribeToFormChanges();
  }

  public subscribeToFormChanges() {
    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((change) => {
      if (this.selectedCategoryId !== change.categoryId) {
        this.buildForm({ ...this.newIdea, categoryId: change.categoryId })
          .pipe(take(1))
          .subscribe((form) => {
            const oldValues = this.form.value;
            const { custom, ...commonValues } = oldValues;

            this.form = form;
            this.form.patchValue(commonValues);

            const customValuesToPatch = this.form.value.custom.map((value: IdeaCustomFieldValue) => {
              const oldValue = oldValues.custom.find((oldValue: IdeaCustomFieldValue) => oldValue.id === value.id);
              if (oldValue) {
                return { ...value, value: oldValue.value };
              }
              return value;
            });

            this.form.controls.custom.patchValue(customValuesToPatch);
            this.subscribeToFormChanges();
          });
      }
      this.selectedCategoryId = change.categoryId;
    });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public close() {
    this.dialogRef.close();
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.isSending = true;
    this.saveNewCategory()
      .pipe(
        map((categoryId): Idea => {
          return {
            ...this.newIdea,
            ...this.form.value,
            categoryId,
            custom: (this.form.value.custom || []).filter((cv: IdeaCustomFieldValue) => {
              return Array.isArray(cv.value) ? cv.value.length > 0 : cv.value !== '' && cv.value !== null;
            }),
          };
        }),
        switchMap((idea: Idea) => {
          return this.ideasStore.add(idea);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((savedIdea) => {
        this.isSending = false;
        this.newIdea = savedIdea;
      });
  }

  public redirectToIdea() {
    this.close();
    this.router.navigate(['/', this.newIdea.shortId]);
  }

  public redirectToIdeas() {
    this.close();
    this.router.navigate(this.routerExService.getIdeasListRoute(this.newIdea.productKey));
  }

  public selectNewCategory(cat: Readonly<Category>) {
    this.newCategory = cat;
  }

  private saveNewCategory() {
    const categoryId = this.form.value.categoryId as string;

    if (this.newCategory && this.newCategory.id === categoryId) {
      return this.categoriesStore
        .add({
          ...this.newCategory,
          id: void 0,
        })
        .pipe(map((category) => category.id));
    } else if (!categoryId) {
      return throwError(() => new Error('categoryId is empty!'));
    }

    return of(categoryId);
  }

  public attachFiles(files: FileList) {
    this.attachService.attachFiles(files, this.newIdea).pipe(takeUntil(this.destroy$)).subscribe();
  }

  private onAttachAdded = (e: AttachEvent) => {
    const formAttaches = this.form.get('attaches')!.value || [];
    this.form.get('attaches')!.setValue([...formAttaches, e.attachment]);

    setTimeout(() => this.scrollToEnd(), 0);
  };

  private scrollToEnd() {
    const scrollConteiner: HTMLDivElement | null = this.scrollConteinerRef ? this.scrollConteinerRef.nativeElement : null;

    if (scrollConteiner) {
      scrollConteiner.scrollTop = scrollConteiner.scrollHeight;
    }
  }

  private buildNewIdea(): Observable<Readonly<Idea>> {
    return forkJoin([
      this.categoriesStore.selectedId$.pipe(take(1)), //
      this.categoriesStore.list$.pipe(take(1)),
      this.importancesStore.list$.pipe(take(1)),
    ]).pipe(
      switchMap(([selectedCategoryId, categories, importances]) => {
        let { importanceId, categoryId } = this.data || ({} as CreateIdeaDialogInput);

        categoryId = categoryId || selectedCategoryId || categories.find((c) => c.isDefault)?.id;
        importanceId = importanceId || importances.length > 0 ? importances[0].id : void 0;

        return this.entityFactory.createIdea({
          categoryId,
          importanceId,
        });
      }),
    );
  }

  private buildForm(idea: Readonly<Idea>): Observable<UntypedFormGroup> {
    return combineLatest([this.customFieldsStore.enabledList$, this.formsStore.list$]).pipe(
      take(1),
      map(([fields, forms]) => {
        const category = idea.categoryId as string;
        const formCategory = forms.find((form) => form.categories.includes(category)) || forms.find((form) => form.isDefault);
        if (!formCategory) return [];

        return formCategory.fields
          .map((field) => {
            const { id, isRequired } = field;
            const matchedField = fields.find((field) => field.id === id);

            if (!matchedField) return void 0;

            let updatedField = { ...matchedField };
            const validation = matchedField.validation;
            if (validation && !!validation.find((validationItem) => validationItem.type === CUSTOM_FIELDS_VALIDATIONS.required.type)) {
              if (!isRequired) {
                updatedField = { ...matchedField, validation: validation.filter((v) => v.type !== CUSTOM_FIELDS_VALIDATIONS.required.type) };
              }
            } else if (isRequired) {
              updatedField = { ...matchedField, validation: [...(validation || []), CUSTOM_FIELDS_VALIDATIONS.required] };
            }

            const mappedField = { ...updatedField };
            this.customFieldsMap.set(mappedField.id, mappedField);
            return mappedField;
          })
          .filter((field) => !!field);
      }),
      map((fields) => {
        return new UntypedFormGroup({
          name: new UntypedFormControl(idea.name, { validators: [Validators.required] }),
          categoryId: new UntypedFormControl(idea.categoryId, { validators: [Validators.required] }),
          creator: new UntypedFormControl(idea.creator, { validators: [Validators.required] }),
          description: new UntypedFormControl(idea.description),
          importanceId: new UntypedFormControl(idea.importanceId),
          isPublic: new UntypedFormControl(idea.isPublic),
          attaches: new UntypedFormControl(idea.attaches),
          custom: this.customFieldsForms.createFormArray(fields as readonly CustomField[], idea.custom),
        });
      }),
    );
  }
}
