import { Component, ElementRef, forwardRef, HostListener, Input, OnDestroy, OnInit, SecurityContext, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { BodyComponentRef } from 'src/app/core/services/dom.service';
import { clearElement } from 'src/app/core/utils/dom-utils';
import { TextToolbarComponent } from 'src/app/editors/components/text-toolbar/text-toolbar.component';
import { TextToolbarService } from 'src/app/editors/services/text-toolbar.service';

@Component({
  selector: 'craft-text-editor',
  templateUrl: './text-editor.component.html',
  styleUrls: ['./text-editor.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextEditorComponent),
      multi: true,
    },
  ],
})
export class TextEditorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  public toolbarRef: BodyComponentRef<TextToolbarComponent>;

  @Input() public maxlength = 10000;
  @Input() public placeholder = 'Search';
  @ViewChild('textEditor', { static: true }) public textEditorRef: ElementRef;

  public onChange = (_: string) => {};
  public onTouched = () => {};

  constructor(private toolbarService: TextToolbarService, private domSanitizer: DomSanitizer) {}

  public ngOnInit() {
    this.toolbarRef = this.toolbarService.register();
    document.execCommand('defaultParagraphSeparator', false, 'p');
  }

  public ngOnDestroy() {
    this.toolbarRef.destroy();
  }

  @HostListener('document:selectionchange')
  public onDomSelection() {
    const selection = window.getSelection();

    if (selection && selection.type === 'Range' && this.inputDiv?.contains(selection.focusNode)) {
      this.toolbarService.show(this.toolbarRef.ref.instance, selection);
      return;
    }

    this.toolbarService.hide(this.toolbarRef.ref.instance);
  }

  @HostListener('paste', ['$event'])
  public onPaste(e: ClipboardEvent) {
    if (e.clipboardData) {
      e.preventDefault();
      let content = e.clipboardData.getData('text/plain');
      content = content.replace(/\n/g, '<br/>');
      const sanitizedContent = this.domSanitizer.sanitize(SecurityContext.HTML, content);
      document.execCommand('insertHTML', false, sanitizedContent === null ? undefined : sanitizedContent);
    }
  }

  @HostListener('input')
  public onInput() {
    const div = this.inputDiv;
    if (!div) return;

    let html: string = div.innerHTML;
    const text: string = div.innerText.trim();

    if (text === '') {
      html = '';
      clearElement(div);
    }

    if (html.length <= this.maxlength) {
      this.onChange(html);
      this.onDomSelection();
    }
  }

  @HostListener('blur')
  public onBlur() {
    this.onInput();
  }

  public registerOnChange(fn: (_: string) => void): void {
    this.onChange = fn;
  }

  public writeValue(value: string): void {
    const div = this.inputDiv;
    if (div) {
      div.innerHTML = value;
    }
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.inputDiv?.setAttribute('disabled', 'disabled');
    } else {
      this.inputDiv?.removeAttribute('disabled');
    }
  }

  private get inputDiv(): HTMLDivElement | undefined {
    return this.textEditorRef?.nativeElement;
  }
}
