import { AfterViewInit, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, combineLatest, of, throwError } from 'rxjs';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Category } from 'src/app/core/models/categories.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { DBIdea, IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { AttachEvent, AttachService } from 'src/app/core/services/backend/attaches-backend.service';
import { CategoriesStoreService } from 'src/app/core/store/services/categories-store.service';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { FormsStoreService } from 'src/app/core/store/services/forms-store.service';
import { IdeasStoreService } from 'src/app/core/store/services/ideas-store.service';
import { mergeArrays } from 'src/app/core/utils/app-utils';
import { getFormValidationErrors } from 'src/app/core/utils/form-utils';
import { CustomFieldsFormsService } from 'src/app/shared/services/custom-fields-forms.service';

@Component({
  templateUrl: './idea-edit.component.html',
  styleUrls: ['./idea-edit.component.scss'],
})
export class IdeaEditDialogComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('nameInput') public nameInputRef: ElementRef;
  @ViewChild('scrollConteiner') public scrollConteinerRef: ElementRef;

  public form: UntypedFormGroup;
  public isSending = false;
  public isSubmitted = false;
  public newCategory?: Readonly<Category>;

  private destroy$ = new Subject<void>();

  constructor(
    private ideasStore: IdeasStoreService,
    private attachService: AttachService,
    private formsStore: FormsStoreService,
    private categoriesStore: CategoriesStoreService,
    private customFieldsStore: CustomFieldsStoreService,
    private customFieldsForms: CustomFieldsFormsService,
    private dialogRef: MatDialogRef<IdeaEditDialogComponent, DBIdea>,
    @Inject(MAT_DIALOG_DATA) public idea: DBIdea,
  ) {}

  public get isAnyUploading() {
    return this.attachService.uploadingSize > 0;
  }

  public get formErrors(): number {
    if (!this.isSubmitted) return 0;
    if (this.form.valid) return 0;

    return getFormValidationErrors(this.form).length;
  }

  public ngOnInit() {
    this.buildForm(this.idea)
      .pipe(takeUntil(this.destroy$))
      .subscribe((form) => {
        const patch = {
          ...this.idea,
          custom: mergeArrays('guid', form.value.custom, this.idea.custom),
        };

        this.form = form;
        this.form.patchValue(patch, { emitEvent: false });
      });

    this.attachService.attach$
      .pipe(
        takeUntil(this.destroy$), //
      )
      .subscribe(this.onAttachAdded);
  }

  public ngAfterViewInit() {
    this.setNameFocus();
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public attachFiles(files: FileList) {
    this.attachService.attachFiles(files, this.idea).pipe(takeUntil(this.destroy$)).subscribe();
  }

  public selectNewCategory(cat: Readonly<Category>) {
    this.newCategory = cat;
  }

  public close() {
    this.dialogRef.close();
  }

  public submit() {
    this.isSubmitted = true;
    if (this.form.invalid) return;

    this.isSending = true;
    this.saveNewCategory()
      .pipe(
        map((categoryId): DBIdea => {
          return {
            ...this.idea,
            ...this.form.value,
            categoryId,
            custom: (this.form.value.custom || []).filter((cv: IdeaCustomFieldValue) => {
              return Array.isArray(cv.value) ? cv.value.length > 0 : cv.value !== '' && cv.value !== null;
            }),
          };
        }),
        switchMap((idea: DBIdea) => {
          return this.ideasStore.update(idea);
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((idea) => {
        this.isSending = false;
        this.dialogRef.close(idea);
      });
  }

  private setNameFocus() {
    if (this.nameInputRef) {
      this.nameInputRef.nativeElement.focus();
    }
  }

  private onAttachAdded = (e: AttachEvent) => {
    const formAttaches = this.form.get('attaches')!.value || [];
    this.form.get('attaches')!.setValue([...formAttaches, e.attachment]);

    setTimeout(() => this.scrollToEnd(), 0);
  };

  private scrollToEnd() {
    const scrollConteiner: HTMLDivElement | null = this.scrollConteinerRef ? this.scrollConteinerRef.nativeElement : null;

    if (scrollConteiner) {
      scrollConteiner.scrollTop = scrollConteiner.scrollHeight;
    }
  }

  private saveNewCategory() {
    const categoryId = this.form.value.categoryId as string;

    if (this.newCategory && this.newCategory.id === categoryId) {
      return this.categoriesStore
        .add({
          ...this.newCategory,
          id: void 0,
        })
        .pipe(
          map((category) => {
            return category.id as string;
          }),
        );
    } else if (!categoryId) {
      return throwError(() => new Error('categoryId is empty!'));
    }

    return of(categoryId);
  }

  private buildForm(idea: Readonly<DBIdea>): Observable<UntypedFormGroup> {
    return combineLatest([this.customFieldsStore.map$, this.formsStore.list$]).pipe(
      take(1),
      map(([fields, forms]) => {
        const createForm = idea.categoryId ? forms.find((form) => form.categories.includes(idea.categoryId!)) : void 0;
        const formFieldsIds = (createForm?.fields || []).map((ff) => ff.id);
        const ideaFieldsIds = (idea.custom || []).map((cf) => cf.id);
        const allFieldsIds = [...new Set([...ideaFieldsIds, ...formFieldsIds])];

        const ideaFields = allFieldsIds.reduce<CustomField[]>((acc, id) => {
          const field = fields.get(id);
          if (field?.isEnabled) acc.push(field);
          return acc;
        }, []);

        return new UntypedFormGroup({
          name: new UntypedFormControl(idea.name, { validators: [Validators.required] }),
          categoryId: new UntypedFormControl(idea.categoryId, { validators: [Validators.required] }),
          creator: new UntypedFormControl(idea.creator, { validators: [Validators.required] }),
          description: new UntypedFormControl(idea.description),
          importanceId: new UntypedFormControl(idea.importanceId),
          isPublic: new UntypedFormControl(idea.isPublic),
          attaches: new UntypedFormControl(idea.attaches),
          custom: this.customFieldsForms.createFormArray(ideaFields, idea.custom),
        });
      }),
    );
  }
}
