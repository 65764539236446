import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CraftForm, DBCraftForm, FormsListResponse } from 'src/app/core/models/forms.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class FormsBackendService {
  constructor(private bs: BackendService) {}

  public getList(): Observable<FormsListResponse> {
    return this.bs.get('portal/form').pipe(
      map((result) => {
        const response: FormsListResponse = {
          ids: [],
          forms: {},
        };

        result.list.forEach((c: DBCraftForm) => {
          response.ids.push(c.id);
          response.forms[c.id] = this.mapForm(c);
        });

        return response;
      }),
    );
  }

  public getDetails(id: string): Observable<DBCraftForm> {
    return this.bs.get(`portal/form/${id}`).pipe(
      map((form) => {
        return this.mapForm(form);
      }),
    );
  }

  public update(form: DBCraftForm): Observable<DBCraftForm> {
    return this.bs.post(`portal/form/${form.id}`, form).pipe(map(() => form));
  }

  public add(form: CraftForm): Observable<DBCraftForm> {
    return this.bs.post(`portal/form`, form).pipe(
      map((res) => {
        return this.mapForm({ ...form, ...res });
      }),
    );
  }

  public delete(formId: string): Observable<void> {
    return this.bs.delete(`portal/form/${formId}`).pipe(map(() => {}));
  }

  private mapForm(form: any): DBCraftForm {
    if (form.createdDt) {
      form.createdDt = new Date(form.createdDt);
    }
    return form as DBCraftForm;
  }
}
