<div class="cat-dd-wrap" [ngSwitch]="ups.allowCreateCategory">
  <span class="dd-title">{{ 'common.text21' | craftTerm }}:</span>
  <craft-categories-autocomplete
    *ngSwitchCase="true"
    [selectedId]="categoryId"
    [newCategoryId]="newCategoryId"
    (enterPressed)="onEnter($event)"
    (select)="updateValue($event)"
  ></craft-categories-autocomplete>
  <craft-categories-select *ngSwitchCase="false" [selectedId]="categoryId" (select)="updateValue($event)"></craft-categories-select>
</div>
